import Vue from 'vue'
import VueRouter from 'vue-router'
import H5 from './h5'
import Pc from './pc'

Vue.use(VueRouter)

const routes = [
  ...Pc,
  ...H5,
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
