const keyword = "成都扬帆科技有限公司官网,售楼系统,房企CRM系统";
const description = "卖楼,售楼,卖楼系统,售楼系统,房地产售楼,房地产卖楼,卖房,售房,卖房系统,售房系统,房地产售房,,房地产卖房,房地产,房地产营销,房地产crm,房地产erp"

export default
  [
    {
      path: '/h5',
      meta: {
        type: "index"
        ,
        title: '扬帆启初-官网',
        content: {
          keywords: keyword,
          description: description,
        },
      },
      component: () => import(/* webpackChunkName: "about" */ '@/views/H5/Index.vue'),

    },
    {
      path: '/h5/more',
      meta: {
        type: "more"
        ,
        content: {
          keywords: keyword,
          description: description,
        },
        title: '扬帆启初-更多详情'
      },
      component: () => import(/* webpackChunkName: "about" */ '@/views/H5/More.vue')
    },
    {
      path: '/h5/service',
      meta: {
        type: "service"
        ,
        content: {
          keywords: keyword,
          description: description,
        },
        title: '扬帆启初-服务支持'
      },
      component: () => import(/* webpackChunkName: "about" */ '@/views/H5/Service.vue')
    },
    {
      path: '/h5/cooperation',
      meta: {
        type: "cooperation"
        ,
        content: {
          keywords: keyword,
          description: description,
        },
        title: '扬帆启初-渠道合作'
      },
      component: () => import(/* webpackChunkName: "about" */ '@/views/H5/Cooperation.vue')
    }, {
      path: '/h5/about',
      meta: {
        type: "about"
        ,
        content: {
          keywords: keyword,
          description: description,
        },
        title: '扬帆启初-服务支持'
      },
      component: () => import(/* webpackChunkName: "about" */ '@/views/H5/About.vue')
    }, {
      path: '/h5/app',
      meta: {
        type: "App"
        ,
        content: {
          keywords: keyword,
          description: description,
        },
        title: '扬帆启初-App下载'
      },
      component: () => import(/* webpackChunkName: "about" */ '@/views/H5/App.vue')
    },
    {
      path: '/h5/conceal',
      meta: {
        type: "Index"
        ,
        content: {
          keywords: keyword,
          description: description,
        },
        title: '扬帆启初-隐私政策'
      },
      component: () => import(/* webpackChunkName: "about" */ '@/views/H5/Conceal.vue')
    },
    {
      path: '/h5/product/crm',
      meta: {
        type: "App"
        ,
        content: {
          keywords: keyword,
          description: description,
        },
        title: '扬帆启初-房地产CRM营销管理系统'
      },
      component: () => import(/* webpackChunkName: "about" */ '@/views/H5/product/Crm.vue')
    },
    {
      path: '/h5/product/xcx',
      meta: {
        type: "App"
        , keywords: keyword,
        description: description,
        title: '扬帆启初-房地产全民营销小程序'
      },
      component: () => import(/* webpackChunkName: "about" */ '@/views/H5/product/Xcx.vue')
    },
    {
      path: '/h5/product/ydxx',
      meta: {
        type: "App"
        ,
        content: {
          keywords: keyword,
          description: description,
        },
        title: '扬帆启初-移动行销系统'
      },
      component: () => import(/* webpackChunkName: "about" */ '@/views/H5/product/Ydxx.vue')
    },
    {
      path: '/h5/product/lfdj',
      meta: {
        type: "App"
        ,
        content: {
          keywords: keyword,
          description: description,
        },
        title: '扬帆启初-来访登记系统'
      },
      component: () => import(/* webpackChunkName: "about" */ '@/views/H5/product/Lfdj.vue')
    },
    {
      path: '/h5/product/fyxcx',
      meta: {
        type: "App"
        ,
        content: {
          keywords: keyword,
          description: description,
        },
        title: '扬帆启初-房源展示系统'
      },
      component: () => import(/* webpackChunkName: "about" */ '@/views/H5/product/Fyzs.vue')
    },
    {
      path: '/h5/product/ywlc',
      meta: {
        type: "App"
        ,
        content: {
          keywords: keyword,
          description: description,
        },
        title: '扬帆启初-业务流程系统'
      },
      component: () => import(/* webpackChunkName: "about" */ '@/views/H5/product/Ywlc.vue')
    },



    {
      path: '/h5/product/zhgd',
      meta: {
        type: "App"
        ,
        content: {
          keywords: keyword,
          description: description,
        },
        title: '扬帆启初-智慧工地管理系统'
      },
      component: () => import(/* webpackChunkName: "about" */ '@/views/H5/product/Zhgd.vue')
    }, {
      path: '/h5/product/zxkp',
      meta: {
        type: "App"
        ,
        content: {
          keywords: keyword,
          description: description,
        },
        title: '扬帆启初-在线开盘系统'
      },
      component: () => import(/* webpackChunkName: "about" */ '@/views/H5/product/Zxkp.vue')
    }, {
      path: '/h5/product/cbgl',
      meta: {
        type: "App"
        ,
        content: {
          keywords: keyword,
          description: description,
        },
        title: '扬帆启初-房地产成本管理系统'
      },
      component: () => import(/* webpackChunkName: "about" */ '@/views/H5/product/Cbgl.vue')
    }, {
      path: '/h5/product/cqgl',
      meta: {
        type: "App"
        ,
        content: {
          keywords: keyword,
          description: description,
        },
        title: '扬帆启初-拆迁管理系统'
      },
      component: () => import(/* webpackChunkName: "about" */ '@/views/H5/product/Cqgl.vue')
    }, {
      path: '/h5/product/dhhf',
      meta: {
        type: "App"
        ,
        content: {
          keywords: keyword,
          description: description,
        },
        title: '扬帆启初-客户电话回访管理'
      },
      component: () => import(/* webpackChunkName: "about" */ '@/views/H5/product/Dhhf.vue')
    }, {
      path: '/h5/product/qcxt',
      meta: {
        type: "App"
        ,
        content: {
          keywords: keyword,
          description: description,
        },
        title: '扬帆启初-房地产前策系统'
      },
      component: () => import(/* webpackChunkName: "about" */ '@/views/H5/product/Qcxt.vue')
    }, {
      path: '/h5/product/qdgl',
      meta: {
        type: "App"
        ,
        content: {
          keywords: keyword,
          description: description,
        },
        title: '扬帆启初-渠道管理系统'
      },
      component: () => import(/* webpackChunkName: "about" */ '@/views/H5/product/Qdgl.vue')
    }, {
      path: '/h5/product/wygl',
      meta: {
        type: "App"
        ,
        content: {
          keywords: keyword,
          description: description,
        },
        title: '扬帆启初-物业管理系统'
      },
      component: () => import(/* webpackChunkName: "about" */ '@/views/H5/product/Wygl.vue')
    }
    , {
      path: '/h5/product/syzn',
      meta: {
        type: "App"
        ,
        content: {
          keywords: keyword,
          description: description,
        },
        title: '扬帆启初-房地产商业智能系统'
      },
      component: () => import(/* webpackChunkName: "about" */ '@/views/H5/product/Syzn.vue')
    },
    {
      path: '/h5/product/zbcg',
      meta: {
        type: "App"
        ,
        content: {
          keywords: keyword,
          description: description,
        },
        title: '扬帆启初-招标采购'
      },
      component: () => import(/* webpackChunkName: "about" */ '@/views/H5/product/Zbcg.vue')
    },
    {
      path: '/h5/userconceal',
      meta: {
        type: "Index"
        ,
        content: {
          keywords: keyword,
          description: description,
        },
        title: '扬帆启初-服务协议'
      },
      component: () => import(/* webpackChunkName: "about" */ '@/views/H5/UserConceal.vue')
    },
  ]