const keyword = "成都扬帆科技有限公司官网,售楼系统,房企CRM系统";
const description = "卖楼,售楼,卖楼系统,售楼系统,房地产售楼,房地产卖楼,卖房,售房,卖房系统,售房系统,房地产售房,,房地产卖房,房地产,房地产营销,房地产crm,房地产erp"

export default
    [
        {
            path: '/',
            meta: {
                type: "Index"
                ,
                title: '扬帆启初-官网',
                content: {
                    keywords: keyword,
                    description: description,
                },
                activeName: "1",
            },
            component: () => import(/* webpackChunkName: "about" */ '@/views/PC/Index.vue')
        },
        {
            path: '/download',
            meta: {
                type: "Index"
                ,
                title: '扬帆启初-app下载',
                content: {
                    keywords: keyword,
                    description: description,
                },
            },
            component: () => import(/* webpackChunkName: "about" */ '@/views/PC/Download.vue')
        },

        {
            path: '/about',
            meta: {
                type: "Index"
                ,
                title: '扬帆启初-关于我们',
                content: {
                    keywords: keyword,
                    description: description,
                },
                activeName: "3",

            },
            component: () => import(/* webpackChunkName: "about" */ '@/views/PC/About.vue')
        },
        {
            path: '/service',
            meta: {
                type: "Index"
                ,
                title: '扬帆启初-服务支持',
                content: {
                    keywords: keyword,
                    description: description,
                },
                activeName: "5",

            },
            component: () => import(/* webpackChunkName: "about" */ '@/views/PC/Service.vue')
        },
        {
            path: '/cooperation',
            meta: {
                type: "Index"
                ,
                title: '扬帆启初-渠道合作',
                content: {
                    keywords: keyword,
                    description: description,
                },
                activeName: "4",

            },
            component: () => import(/* webpackChunkName: "about" */ '@/views/PC/Cooperation.vue')
        },
        {
            path: '/conceal',
            meta: {
                type: "Index"
                ,
                title: '扬帆启初-隐私政策',
                content: {
                    keywords: keyword,
                    description: description,
                },
            },
            component: () => import(/* webpackChunkName: "about" */ '@/views/PC/Conceal.vue')
        }, {
            path: '/userconceal',
            meta: {
                type: "Index"
                ,
                content: {
                    keywords: keyword,
                    description: description,
                },
                title: '扬帆启初-服务协议'
            },
            component: () => import(/* webpackChunkName: "about" */ '@/views/PC/UserConceal.vue')
        },

        {
            path: '/product/crm',
            meta: {
                type: "App"
                ,
                content: {
                    keywords: keyword,
                    description: description,
                },
                title: '扬帆启初-房地产CRM营销管理系统'
            },
            component: () => import(/* webpackChunkName: "about" */ '@/views/PC/product/Crm.vue')
        },
        {
            path: '/product/xcx',
            meta: {
                type: "App"
                ,
                content: {
                    keywords: keyword,
                    description: description,
                },
                title: '扬帆启初-房地产全民营销小程序'
            },
            component: () => import(/* webpackChunkName: "about" */ '@/views/PC/product/Xcx.vue')
        },
        {
            path: '/product/ydxx',
            meta: {
                type: "App"
                ,
                content: {
                    keywords: keyword,
                    description: description,
                },
                title: '扬帆启初-移动行销系统'
            },
            component: () => import(/* webpackChunkName: "about" */ '@/views/PC/product/Ydxx.vue')
        },
        {
            path: '/product/lfdj',
            meta: {
                type: "App"
                ,
                content: {
                    keywords: keyword,
                    description: description,
                },
                title: '扬帆启初-来访登记系统'
            },
            component: () => import(/* webpackChunkName: "about" */ '@/views/PC/product/Lfdj.vue')
        },
        {
            path: '/product/fyxcx',
            meta: {
                type: "App"
                ,
                content: {
                    keywords: keyword,
                    description: description,
                },
                title: '扬帆启初-房源展示系统'
            },
            component: () => import(/* webpackChunkName: "about" */ '@/views/PC/product/Fyzs.vue')
        },
        {
            path: '/product/ywlc',
            meta: {
                type: "App"
                ,
                content: {
                    keywords: keyword,
                    description: description,
                },
                title: '扬帆启初-业务流程系统'
            },
            component: () => import(/* webpackChunkName: "about" */ '@/views/PC/product/Ywlc.vue')
        },



        {
            path: '/product/zhgd',
            meta: {
                type: "App"
                ,
                content: {
                    keywords: keyword,
                    description: description,
                },
                title: '扬帆启初-智慧工地管理系统'
            },
            component: () => import(/* webpackChunkName: "about" */ '@/views/PC/product/Zhgd.vue')
        }, {
            path: '/product/zxkp',
            meta: {
                type: "App"
                ,
                content: {
                    keywords: keyword,
                    description: description,
                },
                title: '扬帆启初-在线开盘系统'
            },
            component: () => import(/* webpackChunkName: "about" */ '@/views/PC/product/Zxkp.vue')
        }, {
            path: '/product/cbgl',
            meta: {
                type: "App"
                ,
                content: {
                    keywords: keyword,
                    description: description,
                },
                title: '扬帆启初-房地产成本管理系统'
            },
            component: () => import(/* webpackChunkName: "about" */ '@/views/PC/product/Cbgl.vue')
        }, {
            path: '/product/cqgl',
            meta: {
                type: "App"
                ,
                content: {
                    keywords: keyword,
                    description: description,
                },
                title: '扬帆启初-拆迁管理系统'
            },
            component: () => import(/* webpackChunkName: "about" */ '@/views/PC/product/Cqgl.vue')
        }, {
            path: '/product/dhhf',
            meta: {
                type: "App"
                ,
                content: {
                    keywords: keyword,
                    description: description,
                },
                title: '扬帆启初-客户电话回访管理'
            },
            component: () => import(/* webpackChunkName: "about" */ '@/views/PC/product/Dhhf.vue')
        }, {
            path: '/product/qcxt',
            meta: {
                type: "App"
                ,
                content: {
                    keywords: keyword,
                    description: description,
                },
                title: '扬帆启初-房地产前策系统'
            },
            component: () => import(/* webpackChunkName: "about" */ '@/views/PC/product/Qcxt.vue')
        }, {
            path: '/product/qdgl',
            meta: {
                type: "App"
                ,
                content: {
                    keywords: keyword,
                    description: description,
                },
                title: '扬帆启初-渠道管理系统'
            },
            component: () => import(/* webpackChunkName: "about" */ '@/views/PC/product/Qdgl.vue')
        }, {
            path: '/product/wygl',
            meta: {
                type: "App"
                ,
                content: {
                    keywords: keyword,
                    description: description,
                },
                title: '扬帆启初-物业管理系统'
            },
            component: () => import(/* webpackChunkName: "about" */ '@/views/PC/product/Wygl.vue')
        }
        , {
            path: '/product/syzn',
            meta: {
                type: "App"
                ,
                content: {
                    keywords: keyword,
                    description: description,
                },
                title: '扬帆启初-房地产商业智能系统'
            },
            component: () => import(/* webpackChunkName: "about" */ '@/views/PC/product/Syzn.vue')
        },
        {
            path: '/product/zbcg',
            meta: {
                type: "App"
                ,
                content: {
                    keywords: keyword,
                    description: description,
                },
                title: '扬帆启初-招标采购'
            },
            component: () => import(/* webpackChunkName: "about" */ '@/views/PC/product/Zbcg.vue')
        },

    ]