import Vue from 'vue'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import './plugins/iview.js'

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import MuseUI from 'muse-ui';
import 'muse-ui/dist/muse-ui.css';
import theme from 'muse-ui/lib/theme';
theme.use('dark');

Vue.prototype.axios = axios
Vue.use(MuseUI)
Vue.use(VueAwesomeSwiper)
Vue.config.productionTip = false


router.beforeEach((to, from, next) => {
  const type = to.meta.type
  if (to.meta.title) {
    document.title = to.meta.title
    document.keywords = to.meta.keywords
    document.description = to.meta.description
  }
  if (to.meta.content) {
    let head = document.getElementsByTagName('head');
    let meta = document.createElement('meta');
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
    meta.content = to.meta.content;
    head[0].appendChild(meta)
  }

  if (type === "login") {
    if (window.localStorage.getItem('accessToken')) {
      next();
    } else {
      next('/login');
    }
  } else {
    next();
  }

});


new Vue({
  router,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
